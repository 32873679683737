import { RawMaterialShapesEnum } from "@/enums/costing-parameters/RawMaterialShapesEnum";
import { CostingSimilarPartsInterface } from "@/types/costing/CostingSimilarPartsInterface";

export default () => {
  const getSurfaceProcessesTag = (part: Record<string, any[]>) => {
    if (part.surfaceProcesses?.length > 0) {
      return part.surfaceProcesses.map((item) => item.name);
    }
    return ["No surface processes"];
  };

  const getHeatTreatmentsTag = (part: CostingSimilarPartsInterface) => {
    if (part.targetRow.heatTreatmentNames?.length > 0) {
      return part.targetRow.heatTreatmentNames;
    }
    return ["No heat treatments"];
  };

  const getPartDimensionTag = (part: CostingSimilarPartsInterface) => {
    return `Total ${part.targetRow?.partDimensions || 0} dim / ${
      part.targetRow?.internalCtqDimensions || 0
    } CTQ`;
  };

  const getSizeTag = (part: Record<string, string>) => {
    if (part.shape === RawMaterialShapesEnum.PLATE) {
      const { width, height, length } = part;
      return `${height} x ${width} x ${length} mm`;
    } else if (part.shape === RawMaterialShapesEnum.ROUND_BAR) {
      const { roundBarDiameter, roundBarLength } = part;
      return `D${roundBarDiameter} x L${roundBarLength} mm`;
    }
    return "";
  };

  const getDefaultTag = (propertyValue: string) => {
    if (
      propertyValue &&
      propertyValue !== "-" &&
      propertyValue.toLocaleLowerCase() !== "none"
    ) {
      return propertyValue;
    }
    return "";
  };

  return {
    getSurfaceProcessesTag,
    getHeatTreatmentsTag,
    getPartDimensionTag,
    getSizeTag,
    getDefaultTag,
  };
};
