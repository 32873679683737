import ApiService from "@/services";
import { AxiosResponse } from "axios";
import CostingLogisticProcessStageEnum from "@/enums/costing/costingLogisticProcessStageEnum";
import NewCostingManufacturingProcessInterface from "@/types/costing/NewCostingManufacturingProcessInterface";
import CostingQualityControlInterface from "@/types/costing/CostingQualityControlInterface";
import CostingCustomProcessApiPayloadInterface from "@/types/costing/CostingCustomProcessApiPayloadInterface";
import CostingCustomProcessBaseCostApiPayloadInterface from "@/types/costing/CostingCustomProcessBaseCostApiPayloadInterface";
import CostingLogisticProcessInterface from "@/types/costing/CostingLogisticProcessInterface";
import CostingLogisticProcessCalculations from "@/types/costing/CostingLogisticProcessCalculations";
import UpdateCostingManufacturingProcessInterface from "@/types/costing/UpdateCostingManufacturingProcessInterface";
import CostingLogisticProcessBatchInterface from "@/types/costing/CostingLogisticProcessBatchInterface";
import { CostingSimilarPartsParamsInterface } from "@/types/costing/CostingSimilarPartsInterface";

export default class CostingProductionProcessService {
  static getCostingProductionProcessList(
    costingId: string,
    mfrRegionId: string,
    rfqPartId: string
  ): Promise<AxiosResponse> {
    return ApiService.get("/v2/parts/costing-production-processes", {
      params: { costingId, mfrRegionId, rfqPartId, skip: 0, limit: 99999 },
    });
  }

  static addCostingManufacturingProcess(
    payload: NewCostingManufacturingProcessInterface
  ): Promise<AxiosResponse> {
    return ApiService.post(
      "/v2/parts/costing-manufacturing-processes",
      payload
    );
  }

  static editCostingManufacturingProcess(
    operationId: string,
    payload: Partial<UpdateCostingManufacturingProcessInterface>
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      "/v2/parts/costing-manufacturing-processes",
      payload,
      { params: { operationId } }
    );
  }

  static getCostingManufacturingProcess(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/parts/costing-manufacturing-processes/${id}`);
  }

  static duplicateCostingManufacturingProcess(
    processId: string
  ): Promise<AxiosResponse> {
    return ApiService.post(
      `/v2/parts/costing-manufacturing-processes/${processId}/duplicate`
    );
  }

  static deleteCostingManufacturingProcess(
    processId: string
  ): Promise<AxiosResponse> {
    return ApiService.delete(
      `/v2/parts/costing-manufacturing-processes/${processId}`
    );
  }

  static getCostingQualityControlProcessList(
    costingId: string,
    mfrRegionId: string
  ): Promise<AxiosResponse> {
    return ApiService.get("/v2/parts/costing-quality-controls", {
      params: { costingId, mfrRegionId },
    });
  }

  static getCostingQualityControlCalculations(
    rfqPartId: string,
    costingId: string,
    mfrRegionId: string,
    qualityControlProcessId: string,
    payload: any
  ): Promise<AxiosResponse> {
    return ApiService.get("/v2/parts/costing-quality-controls/calculations", {
      params: {
        rfqPartId,
        costingId,
        mfrRegionId,
        qualityControlProcessId,
        ...payload,
      },
    });
  }

  static addCostingQualityControlProcess(
    payload: CostingQualityControlInterface
  ): Promise<AxiosResponse> {
    return ApiService.post("/v2/parts/costing-quality-controls", payload);
  }

  static editCostingQualityControlProcess(
    operationId: string,
    payload: Partial<CostingQualityControlInterface>
  ): Promise<AxiosResponse> {
    return ApiService.patch("/v2/parts/costing-quality-controls", payload, {
      params: { operationId },
    });
  }

  static getCostingQualityControlProcess(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/parts/costing-quality-controls/${id}`);
  }

  static duplicateCostingQualityControlProcess(
    processId: string
  ): Promise<AxiosResponse> {
    return ApiService.post(
      `/v2/parts/costing-quality-controls/${processId}/duplicate`
    );
  }

  static deleteCostingQualityControlProcess(
    processId: string
  ): Promise<AxiosResponse> {
    return ApiService.delete(`/v2/parts/costing-quality-controls/${processId}`);
  }

  static addCostingCustomProcess(
    payload: CostingCustomProcessApiPayloadInterface
  ): Promise<AxiosResponse> {
    return ApiService.post("/v2/parts/costing-custom-processes", payload);
  }

  static editCostingCustomProcess(
    operationId: string,
    payload: Partial<CostingCustomProcessApiPayloadInterface>
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      `/v2/parts/costing-custom-processes/${operationId}`,
      payload
    );
  }

  static addCostingCustomProcessBaseCost(
    payload: CostingCustomProcessBaseCostApiPayloadInterface
  ): Promise<AxiosResponse> {
    return ApiService.post(
      "/v2/parts/costing-custom-process-base-costs",
      payload
    );
  }

  static editCostingCustomProcessBaseCost(
    payloadWithAdditionalFields: Record<string, any>
  ): Promise<AxiosResponse> {
    const {
      id,
      costingCustomProcessId,
      createdAt,
      updatedAt,
      mfrRegionId,
      ...payload
    } = payloadWithAdditionalFields;
    return ApiService.patch(
      `/v2/parts/costing-custom-process-base-costs/${id}`,
      payload
    );
  }

  static getCostingCustomProcessWithRelations(
    id: string
  ): Promise<AxiosResponse> {
    return ApiService.get(
      `/v2/parts/costing-custom-processes/${id}/with-relations`
    );
  }

  static deleteCostingCustomProcess(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v2/parts/costing-custom-processes/${id}`);
  }

  static addCostingAdministrativeProcess(payload: {
    administrativeProcessId: string;
    costingId: string;
    mfrRegionId: string;
  }): Promise<AxiosResponse> {
    return ApiService.post(
      "/v2/parts/costing-production-processes/administrative-process",
      payload
    );
  }

  static addCostingAdministrativeProcessInitTemplate(
    costingId: string
  ): Promise<AxiosResponse> {
    return ApiService.post(
      "/v2/parts/costing-production-processes/administrative-process/init-administrative-processes-from-template",
      {
        costingId,
      }
    );
  }
  static editCostingAdministrativeProcess(
    operationId: string,
    administrativeProcessId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      "/v2/parts/costing-production-processes/administrative-process",
      { administrativeProcessId },
      { params: { operationId } }
    );
  }

  static getCostingAdministrativeProcess(id: string): Promise<AxiosResponse> {
    return ApiService.get(
      `/v2/parts/costing-production-processes/administrative-process/${id}`
    );
  }

  static deleteCostingAdministrativeProcess(
    id: string
  ): Promise<AxiosResponse> {
    return ApiService.delete(
      `/v2/parts/costing-production-processes/administrative-process/${id}`
    );
  }

  static getCostingLogisticProcessCalculations(
    rfqPartId: string,
    costingId: string,
    mfrRegionId: string,
    stage: CostingLogisticProcessStageEnum,
    payload: CostingLogisticProcessCalculations
  ): Promise<AxiosResponse> {
    return ApiService.get("/v2/parts/costing-logistic-process/calculations", {
      params: { rfqPartId, costingId, mfrRegionId, stage, ...payload },
    });
  }

  static addCostingLogisticProcess(
    rfqPartId: string,
    costingId: string,
    mfrRegionId: string,
    stage: CostingLogisticProcessStageEnum,
    payload: CostingLogisticProcessInterface
  ): Promise<AxiosResponse> {
    return ApiService.post("/v2/parts/costing-logistic-process", {
      rfqPartId,
      costingId,
      mfrRegionId,
      stage,
      ...payload,
    });
  }

  static addCostingLogisticProcessInitTemplate(
    costingId: string
  ): Promise<AxiosResponse> {
    return ApiService.post(
      "/v2/parts/costing-logistic-process/init-logistic-processes-from-template",
      {
        costingId,
      }
    );
  }

  static addPackingProcessInitTemplate(
    costingId: string
  ): Promise<AxiosResponse> {
    return ApiService.post(
      "/v2/parts/costing-logistic-process/init-packing-processes-from-template",
      {
        costingId,
      }
    );
  }

  static addDeliveryProcessInitTemplate(
    costingId: string
  ): Promise<AxiosResponse> {
    return ApiService.post(
      "/v2/parts/costing-logistic-process/init-delivery-processes-from-template",
      {
        costingId,
      }
    );
  }

  static addCostingLogisticProcessBatch(
    batch: CostingLogisticProcessBatchInterface
  ): Promise<AxiosResponse> {
    return ApiService.post("/v2/parts/costing-logistic-process-batches", batch);
  }

  static editCostingLogisticProcess(
    operationId: string,
    payload: CostingLogisticProcessInterface
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/parts/costing-logistic-process`, payload, {
      params: { operationId },
    });
  }

  static editCostingLogisticProcessBatch(
    batch: CostingLogisticProcessBatchInterface
  ): Promise<AxiosResponse> {
    const { id, ...payload } = batch;
    return ApiService.patch(
      `/v2/parts/costing-logistic-process-batches/${id}`,
      payload
    );
  }

  static getCostingLogisticProcess(id: string): Promise<AxiosResponse> {
    return ApiService.get(
      `/v2/parts/costing-logistic-process/${id}/with-relations`
    );
  }

  static deleteCostingLogisticProcess(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v2/parts/costing-logistic-process/${id}`);
  }

  static checkSimilarPartsStorageAvailability(): Promise<AxiosResponse> {
    return ApiService.get(
      "/v2/similar-parts/check-similar-parts-storage-availability"
    );
  }

  static getCostingSimilarParts(
    params: CostingSimilarPartsParamsInterface
  ): Promise<AxiosResponse> {
    return ApiService.get("/v2/similar-parts/find-similar-parts", {
      params,
    });
  }

  static getCostingSimilarPartsByCostingId(params: {
    mfrRegionId: string;
    costingId: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/similar-parts/find-part-info-by-costing", {
      params,
    });
  }

  static updateCostingSimilarPartsStorage(): Promise<AxiosResponse> {
    return ApiService.patch("/v2/similar-parts/update-similar-parts-storage");
  }

  static clearCostingSimilarPartsStorage(): Promise<AxiosResponse> {
    return ApiService.delete("/v2/similar-parts/clear-similar-parts-storage");
  }

  static copySimilarProductionProcesses(
    copyToCostingId: string,
    copyFromCostingId: string
  ): Promise<AxiosResponse> {
    return ApiService.post(
      `v2/parts/costings/${copyToCostingId}/copy-processes-from/${copyFromCostingId}`
    );
  }

  static confirmSimilarProductionProcesses(
    operationId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(
      "v2/parts/costing-production-processes",
      { copiedProcessConfirmed: true },
      { params: { operationId } }
    );
  }

  static removeSimilarProductionProcesses(
    costingId: string
  ): Promise<AxiosResponse> {
    return ApiService.delete(`v2/parts/costings/${costingId}/copied-processes`);
  }
}
