import { ref, Ref } from "vue";

export default (tableRef: Ref<HTMLElement | undefined>) => {
  let resizeObserver;

  const bidsTitleClass = "bids-title";
  const bidsParamsClass = "bids-params";
  const bidsPaginationClass = "bids--page-counters";
  const tableHeaderClass = "n-data-table-base-table-header";
  const tableBodyClass = "n-data-table-tbody";

  const bidsTitleHeight = ref(0);
  const bidsParamsHeight = ref(0);
  const bidsPaginationHeight = ref(0);
  const tableHeaderHeight = ref(0);
  const tableBodyHeight = ref(0);

  const bidsTitleMargin = 33;
  const bidsParamsMargin = 17;
  const bidsPaginationMargin = 20;
  const bodyTableMargin = 2;

  const getHTMLElementByClassName = (className: string) =>
    tableRef.value?.getElementsByClassName(className)[0] as HTMLElement;

  const checkElementByClassName = (
    entry: ResizeObserverEntry,
    className: string
  ) => entry.contentBoxSize && entry.target.classList.value.includes(className);

  const setResizeObserver = () => {
    const bidsTitle = getHTMLElementByClassName(bidsTitleClass);
    const bidsParams = getHTMLElementByClassName(bidsParamsClass);
    const bidsPagination = getHTMLElementByClassName(bidsPaginationClass);
    const tableHeader = getHTMLElementByClassName(tableHeaderClass);
    const tableBody = getHTMLElementByClassName(tableBodyClass);

    resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (checkElementByClassName(entry, bidsTitleClass)) {
          bidsTitleHeight.value =
            entry.contentBoxSize[0].blockSize + bidsTitleMargin;
        }
        if (checkElementByClassName(entry, bidsParamsClass)) {
          bidsParamsHeight.value =
            entry.contentBoxSize[0].blockSize + bidsParamsMargin;
        }
        if (checkElementByClassName(entry, bidsPaginationClass)) {
          bidsPaginationHeight.value =
            entry.contentBoxSize[0].blockSize + bidsPaginationMargin;
        }
        if (checkElementByClassName(entry, tableHeaderClass)) {
          tableHeaderHeight.value = entry.contentBoxSize[0].blockSize;
        }
        if (checkElementByClassName(entry, tableBodyClass)) {
          tableBodyHeight.value =
            entry.contentBoxSize[0].blockSize + bodyTableMargin;
        }
      }
    });

    if (bidsTitle) {
      resizeObserver.observe(bidsTitle);
    }
    if (bidsParams) {
      resizeObserver.observe(bidsParams);
    }
    if (bidsPagination) {
      resizeObserver.observe(bidsPagination);
    }
    if (tableHeader) {
      resizeObserver.observe(tableHeader);
    }
    if (tableBody) {
      resizeObserver.observe(tableBody);
    }
  };

  return {
    setResizeObserver,
    bidsTitleHeight,
    bidsParamsHeight,
    bidsPaginationHeight,
    tableHeaderHeight,
    tableBodyHeight,
  };
};
