const partProperties = [
  {
    key: "number",
    value: "",
  },
  {
    key: "subTech",
    value: "",
  },
  {
    key: "complexityCode",
    value: "",
  },
  {
    key: "material",
    value: "",
  },
  {
    key: "surfaceProcesses",
    value: "",
  },
  {
    key: "partNetWeight",
    value: "",
  },
  {
    key: "materialWeight",
    value: "",
  },
  {
    key: "width",
    value: "",
  },
];

export default partProperties;
