import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0081394a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "production-processes-wrapper" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "count" }
const _hoisted_4 = { class: "tooltip" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "tooltip-description production-processes-tooltip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(($props.value as string[])?.slice(-1)[0]), 1),
    ($props.value?.length > 1)
      ? (_openBlock(), _createBlock($setup["NTooltip"], {
          key: 0,
          class: "production-processes-info",
          trigger: "hover",
          "show-arrow": false,
          placement: "top",
          width: "250"
        }, {
          trigger: _withCtx(() => [
            _createElementVNode("span", _hoisted_3, "+" + _toDisplayString($props.value?.length - 1), 1)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString($props.title), 1),
              _createElementVNode("ol", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.value, (process) => {
                  return (_openBlock(), _createElementBlock("li", { key: process }, _toDisplayString(process), 1))
                }), 128))
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}