import { BidStatusEnum } from "@/enums/bids/BidStatusEnum";

export const getBidAdminStatuses = {
  [BidStatusEnum.MANUFACTURER_SELECTION]: "Manufacturer selection",
  [BidStatusEnum.PENDING_DRAWING]: "Pending drawing",
  [BidStatusEnum.READY_TO_SEND]: "Ready to send",
  [BidStatusEnum.BID_SENT]: "Sent",
  [BidStatusEnum.BID_ACCEPTED]: "Accepted",
  [BidStatusEnum.BID_DECLINED]: "Declined",
};
