import { Ref } from "vue/dist/vue";
import QuoteService from "@/services/QuoteService";
import { BidInterface } from "@/types/bids/BidInterface";

export const getBidLinePartPreviews = (
  bids: Ref<BidInterface[]>,
  partIdList: string[],
  previewLoading: Ref<boolean>
) => {
  if (!partIdList.length) {
    return;
  }
  previewLoading.value = true;
  QuoteService.getPartPreviews(partIdList)
    .then((res) => {
      const previewList = res.data.data;
      bids.value.forEach((bid) => {
        const preview = previewList.find(
          (item: { partId: string; newPartId: string }) =>
            bid.data?.partId &&
            (bid.data?.partId === item.partId ||
              bid.data?.partId === item.newPartId)
        );
        if (preview) {
          bid.thumbnailUrl = preview.link;
        }
      });
    })
    .finally(() => {
      previewLoading.value = false;
    });
};
