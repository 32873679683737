export enum BidDataKeysEnum {
  COMPLEXITY_CODE = "complexityCode",
  CHARGEABLE_WEIGHT = "chargeableWeight",
  MATERIAL_WEIGHT = "materialWeight",
  PART_NET_WEIGHT = "partNetWeight",
  WIDTH = "width",
  HEIGHT = "height",
  LENGTH = "length",
  SUB_TECH = "subTech",
  PROD_TECH = "prodTech",
  ROUND_BAR_DIAMETER = "roundBarDiameter",
  ROUND_BAR_LENGTH = "roundBarLength",
  SURFACE_PROCESS_COUNT = "surfaceProcessCount",
}
