export const bidPartsParameterLabels: Record<string, string> = {
  number: "Part number",
  prodTech: "Production tech",
  subTech: "Sub-tech",
  complexityCode: "Complexity level",
  chargeableWeight: "Chargeable weight",
  material: "Raw material",
  surfaceProcesses: "Surface treatment",
  partNetWeight: "Part net weight",
  materialWeight: "Material weight",
  width: "Size",
  height: "Height",
  length: "Length",
  roundBarDiameter: "Round bar diameter",
  roundBarLength: "Round bar length",
};
