import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-829826b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "bids main-container",
  ref: "bidsWrapperRef"
}
const _hoisted_2 = { class: "bids-title page-headline" }
const _hoisted_3 = { class: "menu-container" }
const _hoisted_4 = { class: "bids-params" }
const _hoisted_5 = { class: "flex-wrapper" }
const _hoisted_6 = { class: "bids--page-counters" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      ($setup.showUpdateDeliveryDateModal)
        ? (_openBlock(), _createBlock($setup["UpdateDeliveryDateModal"], {
            key: 0,
            show: $setup.showUpdateDeliveryDateModal,
            "onUpdate:show": [
              _cache[0] || (_cache[0] = ($event: any) => (($setup.showUpdateDeliveryDateModal) = $event)),
              $setup.onUpdateDeliveryDateModalClose
            ],
            id: $setup.updateDeliveryDateId,
            deliveryDate: $setup.updateDeliveryDateDeliveryDate,
            targetDate: $setup.updateDeliveryDateDeliveryDate,
            orderType: '',
            customTitle: 'Update requested delivery date',
            showCurrentDueDate: false,
            updateRequestedDeliveryDateFromBid: true,
            onDateUpdated: $setup.onDeliveryDateUpdated,
            onClose: $setup.onUpdateDeliveryDateModalClose
          }, null, 8, ["show", "id", "deliveryDate", "targetDate"]))
        : _createCommentVNode("", true),
      ($setup.showSelectManufacturerModal)
        ? (_openBlock(), _createBlock($setup["SelectManufacturerModal"], {
            key: 1,
            show: $setup.showSelectManufacturerModal,
            "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => (($setup.showSelectManufacturerModal) = $event)),
            manufacturerList: $setup.manufacturerList,
            selectedBid: $setup.selectedBid,
            onSelect: $setup.onSelectManufacturer,
            onClose: _cache[2] || (_cache[2] = ($event: any) => ($setup.showSelectManufacturerModal = false))
          }, null, 8, ["show", "manufacturerList", "selectedBid"]))
        : _createCommentVNode("", true),
      ($setup.showSendBidsModal)
        ? (_openBlock(), _createBlock($setup["SendBidsModal"], {
            key: 2,
            show: $setup.showSendBidsModal,
            "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => (($setup.showSendBidsModal) = $event)),
            manufacturerList: $setup.manufacturerList,
            onClose: _cache[4] || (_cache[4] = ($event: any) => ($setup.showSendBidsModal = false)),
            onSent: $setup.fetchData
          }, null, 8, ["show", "manufacturerList"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createTextVNode("Bids"),
        _createElementVNode("div", _hoisted_3, [
          ($setup.isSendBidAllowedRole)
            ? (_openBlock(), _createBlock($setup["NButton"], {
                key: 0,
                class: "new-quote-button small-button",
                round: "",
                type: "info",
                disabled: $setup.isSendBidsDisabled,
                onClick: $setup.onSendBidsClicked
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Send bids")
                ]),
                _: 1
              }, 8, ["disabled"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode($setup["BidsFilters"], {
            filterByManufacturer: $setup.filterByManufacturer,
            filterByStatus: $setup.filterByStatus,
            filterByDrawingStatus: $setup.filterByDrawingStatus,
            manufacturers: $setup.manufacturerList,
            loading: $setup.loading,
            filterCounters: $setup.filterCounters,
            isSearchScheduled: $setup.isSearchScheduled,
            onTriggerFilter: $setup.onFilterTriggered,
            onRemoveFilter: $setup.onRemoveFilter,
            onClearFilters: $setup.onClearFilters,
            onScheduleSearch: _cache[5] || (_cache[5] = ($event: any) => ($setup.isSearchScheduled = true)),
            onTriggerScheduledSearch: $setup.onTriggerScheduledSearch
          }, null, 8, ["filterByManufacturer", "filterByStatus", "filterByDrawingStatus", "manufacturers", "loading", "filterCounters", "isSearchScheduled", "onTriggerFilter"])
        ])
      ]),
      _createElementVNode("div", {
        class: "bids--table-wrapper",
        style: _normalizeStyle({ minHeight: `${$setup.scrollableTableHeight}px` })
      }, [
        _createVNode($setup["NDataTable"], {
          class: "bids--table",
          columns: $setup.currentColumns,
          data: $setup.bids,
          singleLine: false,
          rowProps: $setup.rowProps,
          loading: $setup.loading,
          style: _normalizeStyle({ height: `${$setup.tableHeight}px` }),
          flexHeight: "",
          "onUpdate:sorter": $setup.handleSorterChange
        }, null, 8, ["columns", "data", "loading", "style"])
      ], 4),
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createElementVNode("div", { class: "bids--counter" }, _toDisplayString($setup.paginationCurrentItemsMessage), 513), [
          [_vShow, !$setup.loading]
        ]),
        _withDirectives(_createVNode($setup["NPagination"], {
          class: "bids--pagination",
          page: $setup.currentPage,
          "onUpdate:page": [
            _cache[6] || (_cache[6] = ($event: any) => (($setup.currentPage) = $event)),
            _cache[7] || (_cache[7] = ($event: any) => ($setup.onPaginationUpdated()))
          ],
          "page-count": $setup.totalPages,
          "page-slot": 6
        }, null, 8, ["page", "page-count"]), [
          [_vShow, $setup.totalPages > 1]
        ])
      ])
    ], 512), [
      [_vShow, $setup.isBidsRoute]
    ]),
    _createVNode(_component_router_view)
  ], 64))
}